
import React from 'react'
import SlidesContainer from './SlidesContainer'

const Testimonial = () => {

    


  return (
    <div>

        <SlidesContainer />
    </div>
  )
}

export default Testimonial