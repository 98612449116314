
import React from 'react'
import Testimonial from '../Global/Testimonial'

const Hero = () => {
  return (
    <section>
        <Testimonial />
    </section>
  )
}

export default Hero