import React from 'react';


const AnnouncementToolbar = () => {
    return (
        <div className="announcement-toolbar">
            <div className="announcement-text">
            NEWSLINE:ANNOUNCEMENT... COMING SOON!!!!!!!!!
            </div>
        </div>
    );
};

export default AnnouncementToolbar;
