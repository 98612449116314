
import React, { useState } from 'react'


const Trusted = () => {
   
    return (
        <div className='w-[80%]  sm:w-[90%] mx-auto mt-40'>

        </div>
    )
}

export default Trusted